div.div-box-wrap{
    border:solid 1px #CCCCCC;
    min-height: 100px;
    background-color: #f0f3f5;
    margin:10px;
    
}

div.div-box-wrap div.div-box-header{
    border-bottom: solid 1px #CCCCCC;
    padding-left: 20px;
    font-size: .875rem;
    color:#151b1e;
    font-weight: bold;
    min-height: 40px;
    line-height: 40px;
}

div.div-box-wrap div.div-box-body{
    padding: 10px;
    padding-left: 20px;
    font-size: .875rem;
    color:#151b1e;
}


div.div-box-wrap div.div-box-footer{
    border-top: solid 1px #CCCCCC;
    padding: 10px;
    padding-left: 20px;
    font-size: .875rem;
    color:#151b1e;
    font-weight: bold;
}
