/*generic configuration*/
div.input-wrap input,div.input-wrap select{
    padding: .375rem .75rem !important;
    font-size: 14px;
    border: solid 1px black;
    border-radius: 1pt;
    display: block;
    min-width: 100px;
    width: 90%;    
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    height: 35px !important;
    width: 100%;
}

div.input-wrap input[type=checkbox]{
    display: inline;
    min-width: 20px;
    width: 0%;
}

div.input-wrap{
    display: table-cell;
    vertical-align: top;
    padding-top: 5px;
    padding-right: 5px;
}

div.input-wrap > label:not(.after-label){
    font-size: 14px;
    display: block;
    height: 20px;
    min-height: 20px;
}


div.input-wrap.checkbox-wrap {
    vertical-align: bottom;
}

div.input-wrap > label.after-label{
    font-size: 14px;
    display: inline;
    position: relative;
    bottom: 5px;
}

div.input-wrap > input{
    font-size: 14px;
}

div.data-since > .choices > button{
    border: 1px solid black;
    padding: 5px;
}

div.data-since > .choices > button.first{
    border-radius: 2pt 0 0 2pt;
}

div.data-since > .choices > button.first, div.data-since > .choices > button.mid{
    border-right: 0 none;
}

div.data-since > .choices > button.last{
    border-radius: 0 2pt 2pt 0; 
}

div.data-since > input{
    width: 100%;
    margin-top: 5px;
}

div.data-since > .choices > button.selected{
    background-color: white;
    text-decoration: underline;
}

div.data-since > .choices > button:hover{
    cursor: pointer;
    opacity: 0.8;
}

div.input-wrap div.select-between-wrap{
    display: table;
    width: 80%;
}

div.input-wrap div.select-between-wrap > *{
    display: table-cell;
    min-width: 1px;
}

div.input-wrap div.select-between-wrap > select{
    width: 100%;
}

div.input-wrap div.select-between-wrap > button{
    padding-right: 2px;
    padding-left: 2px;
    text-align: center;
}


div.input-wrap div.paginator-wrap > div > *{
    display: table-cell;
    min-width: 1px;
}

div.input-wrap div.paginator-wrap{
    display: table;
    width: 100%;
    padding-top: 21px;
}

div.input-wrap div.paginator-wrap > div > select{
    border: none 0;
    text-decoration: underline;
    cursor: pointer;
    width: 100px;
}

div.input-wrap div.paginator-wrap > div *{
    font-size: 12px;
}

div.input-wrap div.paginator-wrap > div > button{
    border:solid 1px black;
    padding: 5px;
    border-radius: 2pt;
}

button.page-number{
    border-radius: 0;
}
button.page-number.selected{
    background-color: #20A8D8;
    color: white;
}

.inputable-element-wrap i.input-info{
    cursor: pointer;
    height: 35px;
    padding: 0;
    margin: auto;
    line-height: 35px;
    min-height: 35px;
    vertical-align: middle;
    font-size: 30px;
    color: #555555;
    width: 1%;
}

.inputable-element-wrap{
    display:table;
    width: 100%;
}

div.inputable-element-wrap > *{
    display: table-cell;
}

div.inputable-element-wrap div.select-between-wrap{
    display: table-row;
}

div.inputable-element-wrap div.select-between-wrap > *{
    display: table-cell;
    
}