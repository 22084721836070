.gauge-chart-val {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: rgba(36,36,36,.8);
    display: block;
    position: relative;
    top: -40px;
    
}

.gauge-chart-title{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: rgba(36,36,36,.8);
    display: block;
}

.gauge-chart-div-root{
    text-align: center;
}

/*optional*/
div.gauge-chart-title{
    position: relative;
    top:20px;
}

.gauge-chart-val.percent:after{
    content: '%';
}