div.main-view-root{
  width: 100%;
  height: 100%;
  display: table;
}

.main-view-root-div-menu{
  min-width: 50px;
  max-width: 350px;
  display: table-cell;
}

div.main-view-root-div-content{
  display: table-cell;
  vertical-align: top;
  width: 100%;
  border: dotted 1px black;
}

.div-filters{
  display: table;
  width: 100%;
}


.div-filters-cell{
  display: table-cell;
}

.div-filters-row{
  display: table-row;
}

.div-filters-cell label{
  display: block;
}

div.div-search-filter-painel-components{
  border-bottom: 1px dotted black;
}

div.div-search-result{
  padding: 5px;
}

.p-dialog-enter-done{
  width: 80%;
}
