div.div-panel-job-item-root{
    display: block;
    
}

div.div-panel-job-item-details, div.div-panel-job-item-header{
    display: table;
    width: 100%;
}

div.div-panel-job-item-details div.row{
    display: table-row;
    
}

div.div-panel-job-item-details{
    padding-bottom: 20px;
}

div.div-panel-job-item-details i{
    font-size: 25px;
}

.div-panel-job-item-job-content, .job-abstract{
    margin: 10px;
    font-size: 12px;
    padding: 3px;
}

.upper{
    text-transform: uppercase;
}

i.fa.text{
    background-color: rgba(36,36,36,.8);
    text-align: center;
    border-radius: 2pt;
    
    color: white;
    font-size: 21px;
    padding: 1px;
}

div.div-panel-job-item-details div.row div.cell{
    display: table-cell;
    font-size: 12px;
    text-align: center;
     
}

div.div-panel-job-item-details div.icon-label div.lbl{
    max-width: 90px;
    width: 90px;
    color: rgba(36,36,36,.8);
    height: 45px;
    
}

div.div-panel-job-item-details div.icon-label div.left:not(lbl){
    color: #242424;
    
    font-weight: bold;   
    color: rgba(36,36,36,.8);
    
    
}

div.div-panel-job-item-details div.row div.cell.left{
    text-align: left;
}

div.div-panel-job-item-details div.row div.cell.lbl{
    font-weight: bold;
}

div.div-panel-job-item-root > div.div-panel-job-item-header > div{
    display: table-cell;
    font-size: 12px;
    
}

div.div-panel-job-item-root > div.div-panel-job-item-header > div.first{
    width: 100%;
}

div.div-panel-job-item-root > div.div-panel-job-item-header > div div.job-title{
    font-weight: bold;
    font-size: 13px;
    padding-bottom: 2px;
    padding-left: 5px;
}


div.div-panel-job-item-root > div.div-panel-job-item-header > div div.job-abstract{
    padding-left: 5px;
}

div.div-panel-job-item-root i.job-btn{
    font-size: 20px;
    border:solid 1px white;
    padding: 2px;
    margin: 2px;
    border-radius: 4pt;
    background-color: #242424;
    opacity: .6;
    box-shadow: 2px 2px 2px rgba(0,0,0,.3);
}

div.div-panel-job-item-root i.job-btn:hover{
    opacity: 1;
    cursor: pointer;
}

div.div-panel-job-item-root i.job-btn.opened-false{
    color: white;
}

div.div-panel-job-item-root i.job-btn.opened-true{
    color: #F2BE35;
    border-color: #F2BE35;
}

iframe.div-panel-job-item-source-iframe{
    width: 100%;
    margin: 0 auto;
    min-height: 800px;
}

i.icon-toolbox{
    color: #242424;
}

a.job-title-link{
    font-size: 16px;
    text-decoration: none;
    color: #242424;
}

a.job-title-link:hover{
    text-decoration: underline;
    cursor: pointer;
}

div.div-box-body div.div-panel-job-item-details{
    
    min-width: 350px;
}

div.div-box-body div.div-panel-job-item-details > div.row > *{
    border-bottom: solid 1px #CCCCCC;
    vertical-align: middle;
}



.job-details-card div.div-box-wrap{
    min-height:548px;
}

a.toogle-btn{
    cursor: pointer;
}

span.small-icon-sub-title{
    font-size: 8px;
    text-transform: uppercase;
    display: block;
}