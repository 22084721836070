.messages-div-root{
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 2001;
}

.messages-div-message-item{
    background-color: #242424;
    border:solid 1px #20A8D8;
    color: rgba(255,255,255,0.8);
    margin-bottom: 5px;
    word-wrap: break-word;
    border-radius: 4px 4px 4px 4px;
	width: 300px;
	box-shadow: 5px 5px 5px rgba(0,0,0,.3);
	opacity: .8;
	padding: 10px;
}

.messages-div-message-item-title{
	
	margin: 2px;
	color: rgba(255,255,255,1);
	font-size: 10pt;
	font-weight: bold;
	
	top:3px;
	position:relative;
  	    
}

.messages-div-message-item-head{
	
	border-bottom:solid 1px rgba(255,255,255,1);
	padding-bottom: 5px;
	display: none;
	
}

.messages-div-message-item-btn-close{
	
    cursor:pointer;
    font-size: 16px;
    text-align: center;
  	position:relative;
  	top:2px;
  	left:130px;    
    text-decoration:none !important;
    vertical-align:top;
}

.messages-div-message-item-btn-close span{
	
    color:rgba(255,255,255,1);
    border-radius: 3px 3px 3px 3px;
    border: 1px solid rgba(255,255,255,1);
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 2px;
    background-color: #F1F1F1;
    
    
}

.messages-div-message-item-body{
	 font-size: 14pt;	
	 display: block;
	 margin-left: 2px;
	 margin-right: 2px;
	 min-height: 50px;
}

.messages-div-message-item-img{
	width: 32px;
	height: 32px;
	float: left;
	margin: 2px;
	
	background-repeat: no-repeat;
	background-color: transparent;
}

.messages-div-message-item-fechar-todos{
	background-color: #242424;
    border:solid 1px #F2BE35;
    color: rgba(255,255,255,1);
    text-align:center;
    text-decoration:underline;
    font-weight:bold;
    word-wrap: break-word;
	font-size: 16px;
	padding-bottom: 5px;
	padding-top: 5px;
    border-radius: 4px 4px 4px 4px;
    width: 300px;
	cursor: pointer;
	opacity: 0.8;
	padding-left: 10px;
	padding-right: 10px;
}

.messages-div-message-item-fechar-todos:hover{
	cursor: pointer;
	opacity: 1;
}

.messages-div-message-item-hover{
	border-color: #F2BE35;
	cursor: pointer;
	opacity: 1;
}

.img-error{
	background-image: url(error.png);
}

.img-warn{
	background-image: url(warn.png);
}

.img-info{
	background-image: url(info.png);
}

.img-fatal{
	background-image: url(fatal.png);
}