$code-font-size: 120%;

@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "~bootstrap/scss/_root.scss";

html, body{
    font-family: $font-family-sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
}

.p-widget{
    font-family: $font-family-sans-serif;
}

@import "node_modules/bootstrap/scss/_code.scss";

div.main-view-root{
    width: 100%;
    height: 100%;
    display: table;
  }
  
  .main-view-root-div-menu{
    min-width: 45px;
    max-width: 350px;
    display: block;
    width: 220px;
    height: 100%;
    overflow: hidden;
    
  }

.invalid{
    border: solid 1px red !important;
}

  div.main-view-root-div-content{
    border: 0 none;
    display: table-cell;
    height: 100%;
    background-color: #E4E5E6;
    
  }
  
  div.main-view-root-div-top-bar{
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #ffffff;
    display: table;
    box-shadow: 5px 5px 5px rgba(0,0,0,.3);
  }

  div.div-job-item{
    padding: 10px;
    border-radius: 5pt;
    min-height: 100px; 
    margin-top: 10px;
    background-color: #FFFFFF;       
    box-shadow: 5px 5px 5px rgba(0,0,0,.3);
  }

  div.div-search-result{
    padding: 5px !important;
  }

  .full-size{
      width: 100%;
  }
   
  .half-size{
    width: 50%;
  }

  .facets-list{
      display: block !important;
      font-weight: bold;
    }
    
  .max-200px{      
    max-width: 200px;
    white-space: normal !important;
  }

  .fixed-width-300px{      
    max-width: 300px;
    min-width: 300px;
    width: 300px;
    white-space: normal !important;
  }

  .fixed-width-250px{      
    max-width: 250px;
    min-width: 250px;
    width: 250px;
    white-space: normal !important;
  }


  .fixed-width-200px{      
    max-width: 200px;
    min-width: 200px;
    width: 200px;
    white-space: normal !important;
  }

  .fixed-width-140px{      
    max-width: 140px;
    min-width: 140px;
    width: 140px;
    white-space: normal !important;
  }

  .half{
      width: 50%;
      max-width: 50%;
      min-width: 50%;
  }

  .dedup-filter .inputable-element-wrap{
      max-width: 220px;
  }

  .dedup-filter .inputable-element-wrap .p-selectbutton.p-buttonset .p-button{
      border:solid 1px #CCCCCC;
      background-color: transparent;
      border-radius: 1pt;
      height: 36px;
      color: #555555;
  }

  .dedup-filter .inputable-element-wrap .p-selectbutton.p-buttonset .p-button.p-highlight{
    background-color: #CCCCCC;
    color: rgba(0,0,0,.9);
    text-decoration: underline !important;
  }

  .waiting{
     opacity: 0.3;
     cursor: not-allowed; 
  }

  .chart-box-block-btns > button{
    display: block;
    min-width: 180px;
    height: 38px;
    line-height: 26px;
    margin-top: 2px;
  }

  .chart-box-block-btns{
    display: inline-table;
    position: relative;
    margin-top: 8px;
  }

  .table-summary-search tr td{
        border-bottom: 1px solid #CCCCCC; 
        padding: 5px;
  }

  div.box-summary div.div-box-header{
    color: rgba(36,36,36,.8);
  } 

  div.box-summary > div.div-box-header{
    font-size: 16px;
    color: #242424;
  }  
  
  .table-summary-search tr th{
      border-bottom: 1px solid #CCCCCC; 
    padding: 5px;
  }
  .table-summary-search tr td:nth-child(2){
    text-align: right;
    border-left: 1px solid #CCCCCC;
  }

  .table-summary-search{
    width: 90%;
  }

  .display-cell{
      display: table-cell;
  }

  .display-block{
    display: block;
   }

  .display-table{
    display: table;
   }

   .display-table-row{
    display: table-row;
   }

  div.main-view-root-div-top-bar > *{
    display: table-cell;
  }

  div.main-view-root-div-top-bar > i.menu-toggle{
    width: 50px;
  }

  div.user-details{
      text-align: right;
      padding-right: 40px;
  }

  div.user-details i{
      position: relative;
      top:5px;
  }

  .menu-toggle{
      position: relative;
      top: 4px;
      left:15px;
  }

  .menu-toggle:hover{
    cursor: pointer;
    opacity: 0.8;
  }

 
  
  .div-filters{
    display: table;
    width: 100%;
  }
  
  
  .div-filters-cell{
    display: table-cell;
  }
  
  .div-filters-row{
    display: table-row;
  }
  
  .div-filters-cell label{
    display: block;
  }
  
  div.div-search-filter-painel-components{
    border-bottom: none 0px;
  }
  
  div.div-search-result{
    padding: 5px;
  }

div.main-view-root-div-menu{
    background-color: #29363D;
    color: rgba(255,255,255,.4);
    z-index: 2;
}
  
div.menu-div-root{
    text-align: center;
}

div.menu-div-root i.icon-toggle{
    font-size: 38px;
    cursor: pointer;
}

div.menu-div-root.expanded{
    width: 220px;
}

div.menu-div-root.expanded img{
    width: 60%;

}

div.menu-div-root img{
    width:100%; 
}

div.menu-div-root{
    width: 50px;
}

.menu-div-root-text-site{
    font-size: 20px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    color: #20A8D8;
    background-color: white;
    box-shadow: 5px 5px 5px rgba(0,0,0,.3);
}

/*menuitem css expanded*/
div.menu-div-root.expanded div.menu-item-root{
    margin-left: 0px;
    padding: 0px;
    font-weight: normal;
    border-top: 0 none;
}


div.menu-div-root.expanded .menu-items-area > div.menu-item-root{
    border: 0 none;
    border-radius: 4pt;
    margin-bottom: 5px;
    font-weight: bold;
}


div.menu-div-root.expanded div.menu-item-root i{
    font-size: 20px;
    min-width: 24px;
    text-align: center;
    padding-left: 10px;
    padding-right: 2px;
}

div.menu-div-root.expanded div.menu-item-root i.ident{
    padding-left: 20px;
}

div.menu-div-root.expanded div.menu-item-root i.material-icons{
    position: relative;
    top:4px;
}

div.menu-div-root.expanded div.menu-item-root i:hover{
    cursor: pointer;
    text-decoration: underline;
    opacity: 1;
}

div.menu-div-root.expanded div.menu-item-root > div.head{
    display: table;
    width: 100%;
    text-align: left;
}

div.menu-div-root.expanded div.menu-item-root > div.head span{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}


div.menu-div-root.expanded div.menu-item-root > div.body.expanded{
    display: block;
    
}

div.menu-div-root.expanded div.menu-item-root > div.body{
    display: none;
}

div.menu-div-root.expanded div.menu-item-root > div.head > *{
    display: table-cell;
}

div.menu-div-root.expanded div.menu-item-root.selected > div.head{
    background-color: #555555;
    font-weight: bold;
    
}

div.menu-div-root.expanded div.menu-item-root.selected > div.head > i{
    color: #20A8D8;
    
}

div.menu-div-root.expanded div.menu-item-root.selected > div.head > span{
    color: white;
    
}
div.menu-div-root.expanded div.menu-item-root > div.head:hover i{
    color: rgba(255,255,255,1);
}
div.menu-div-root.expanded div.menu-item-root > div.head:hover{
    background-color: #20A8D8;
    opacity: 1;
    color: rgba(255,255,255,1);
}
div.menu-div-root.expanded div.menu-item-root > div.head > span:hover{
    cursor: pointer;
    text-decoration: underline;
    opacity: 1;
}

div.menu-div-root i.not-expanded-icon{
    display: none;
}


/*not expanded view*/
div.menu-div-root{
    overflow: visible;
}

div.menu-div-root:not(.expanded) > div.menu-items-area > div.menu-item-root > i.not-expanded-icon{
    display: block;
}

div.menu-div-root:not(.expanded) > div.menu-items-area > div.menu-item-root > div.head,div.menu-div-root:not(.expanded) > div.menu-items-area > div.menu-item-root:not(.selected) > div.body{
    display: none;
}

div.menu-div-root:not(.expanded) > div.menu-items-area > div.menu-item-root.selected > div.body{
    display: block;
    position: relative;
    width: 300px;
    left: 24px;
    top: -21px;
    background-color: white;
    box-shadow: 10px 10px 5px grey;
}

div.menu-div-root:not(.expanded) div.menu-item-root{
    margin-left: 10px;
    padding: 5px;
    font-weight: normal;
    border-top: solid 1px black;
}


div.menu-div-root:not(.expanded) .menu-items-area > div.menu-item-root{
    border: solid 1px black;
    border-radius: 4pt;
    margin-bottom: 5px;
    font-weight: bold;
}


div.menu-div-root:not(.expanded) div.menu-item-root i{
    font-size: 20px;
    min-width: 24px;
    text-align: center;
}

div.menu-div-root:not(.expanded) div.menu-item-root i:hover{
    cursor: pointer;
    text-decoration: underline;
}

div.menu-div-root:not(.expanded) div.menu-item-root > div.head{
    display: table;
    width: 100%;
    text-align: left;
}

div.menu-div-root:not(.expanded) div.menu-item-root > div.head span{
    width: 100%;
}


div.menu-div-root:not(.expanded) div.menu-item-root > div.body.expanded{
    display: block;
}

div.menu-div-root:not(.expanded) div.menu-item-root > div.body{
    display: none;
}

div.menu-div-root:not(.expanded) div.menu-item-root > div.head > *{
    display: table-cell;
}

div.menu-div-root:not(.expanded) div.menu-item-root.selected > div.head{
    color: white;
}

div.menu-div-root:not(.expanded) div.menu-item-root > div.head > span:hover{
    cursor: pointer;
    text-decoration: underline;
    opacity: 0.8;
}


div.menu-div-root span.menu-item-small-child-number{
    display: block;
    position: absolute;
    border-radius: 15pt;
    background-color: #20A8D8;
    color: white;
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 9px;
    left: 25px;
}

div.menu-div-root.expanded div.menu-item-root > div.body:not(.expanded){
    display: none;
}


/*input components*/
div.input-wrap input,div.input-wrap select{
    border: solid 1px #CCCCCC;
    color: #555555;
    padding: 6px;
    height: 22px;
    min-width: 40px;
}

div.input-wrap select{
    height: 36px;
}

div.input-wrap label{
    font-size: 12px;
    color: rgba(36,36,36,.8);

}

div.input-wrap div.data-since button{
    height: 36px;
    width: 45px;
    color: #555555;
    border: solid 1px #CCCCCC;
    background-color: #EFEFEF;
    font-weight: bold;
}

div.input-wrap div.paginator-wrap > div > button:hover{
    cursor: pointer;
    opacity: .8;
}

div.input-wrap div.paginator-wrap {
    vertical-align: bottom;
}

div.input-wrap div.paginator-wrap > div > button{
    height: 37px;
    width: 45px;
    color: #555555;
    border: solid 1px #CCCCCC;
    background-color: #EFEFEF;
    font-weight: bold;
}

div.input-wrap div.paginator-wrap > div > button:disabled{
    cursor: not-allowed;
    opacity: .4;
}

div.input-wrap div.paginator-wrap > div > span{
    font-size: 14px;
    font-weight: bold;
    padding-left: 5px;
    
}

div.paginator-border-div{
    border: solid 1px #CCCCCC;
    border-radius: 1pt;
    white-space: nowrap;
    min-width: 300px;
    display: table-row;
    
}

div.input-wrap div.paginator-wrap > div > select {
    border: none 0;
    text-decoration: underline;
    cursor: pointer;
    width: 120px;
    height: 37px;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    
    
}


.top{
    vertical-align: top;
}

.hidden{
    display: none;
}


div.input-wrap div.data-since button.selected{
    color: #242424;
    background-color: #CCCCCC;
    font-weight: bold;
}

div.input-wrap div.data-since input[type=date]{
    max-width: 200px;
}


button.toggle-jobs-btn{
    color: #fff;
    border:solid 1px #20a8d8; 
    background-color: #20a8d8;
    width: 150px;
    margin-top: 24px;
    border-radius: 1pt;
    height: 35px;
}

div.div-search-filter-painel-actions button.primary-action, div.div-box-footer button.primary-action{
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8;
    padding: 10px;
    width: 150px;
    margin-top: 20px;
    border-radius: 1pt;
}

div.div-search-filter-painel-actions button.secondary-action{
    color: #151b1e;
    background-color: #a4b7c1;
    border-color: #a4b7c1;
    padding: 10px;
    width: 150px;
    margin-top: 20px;
    border-radius: 1pt;
}

.main-view-root-div-top-bar .current-view{
    font-weight: bold;
    color: #555555;
    text-align: right;
}

div.div-search-filter-painel{
    padding: 0;
}

div.div-wait-modal{
    position: absolute;
    top: 0px;
    left:0px;
    bottom: 0px;
    right: 0px;
    background-color: #242424;
    opacity: .7;
    z-index: 3000;
}

div.div-wait-icon{
    height: 80px;
    width: 80px;
    border-left: #20A8D8 solid 5px;
    border-right: #20A8D8 solid 5px;
    border-top: #555555 solid 5px;
    border-bottom: #555555 solid 5px;
    border-radius: 50pt;
    -webkit-animation: spin 4s infinite linear;
    animation: spin 1s infinite linear;
    position: absolute;
    top:300px;
    left: 50%;
}

div.div-wait-icon-internal{
    height: 40px;
    width: 40px;
    border-left: #F2BE35 solid 5px;
    border-right: #F2BE35 solid 5px;
    border-top: #555555 solid 5px;
    border-bottom: #555555 solid 5px;
    border-radius: 50pt;
    -webkit-animation: spin 4s infinite linear;
    animation: spin 1s infinite linear;
    position: absolute;
    margin-left: 45%;
    margin-top: 10%;
    vertical-align: middle;
    text-align: center;
}



@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}


div.input-auto-complete div.input-auto-complete-div-result-item.selected{
    background-color: #4175ab !important;
    color: white;
    text-decoration: none;
    font-weight: bold;
}

div.input-auto-complete div.input-auto-complete-div-result-item{
    font-size: 14px;
    padding-left: 8px;
    padding-bottom: 3px;
}

div.input-auto-complete div.input-auto-complete-div-result-item:nth-child(odd){
    background-color: #f4f4f4;
}

div.input-auto-complete div.input-auto-complete-div-result{
    border:solid 1px #CCCCCC;
    border-radius: 2pt;
    box-shadow: 5px 5px 5px rgba(0,0,0,.3);
    max-height: 400px;
    overflow-y: scroll;
}

div.build-label{
    font-size: 10px;
    text-align: center;
}

div.input-auto-complete{
    width: 100%;
}

fieldset.fieldset-default{
    border:solid 1px #CCCCCC;
    border-radius: 0;
}

fieldset.fieldset-default legend{
    font-size: 12px;
    font-weight: bold;
    color: rgba(36,36,36,.8);
    border-bottom: 1px solid #CCCCCC;
}

.job-number-result{
    position: absolute;
    margin-top: 2px;
    font-size: 12px;
    margin-left: 140px;
    font-weight: bold;

}
.select-between-wrap > span{
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    
}

div.div-search-result {
    padding: 0px !important;
}

div.data-since > .choices > button.first{
    border-radius: 1pt;
}

div.data-since > .choices > button.last{
    border-radius: 1pt;
}

div.input-wrap{
    white-space: nowrap;
}

.vertical-align-middle{
    vertical-align: middle;
}

.margin-right{
    margin-right: 10px;
}

.main-view-root-div-menu *{
    color: white;
    font-weight: normal;

}

.main-view-root-div-menu i{
    color: #536c79;
}

.menu-div-root-text-site{
    color: #555555;
    font-weight: bold;
    
}

button.page-number{
    border-radius: 0 !important;
}
button.page-number.selected{
    background-color: #20A8D8 !important;
    color: white !important;
    border-color: #20A8D8 !important;
}

div.input-wrap div.data-since button{
    height: 35px;
}

div.input-wrap div.paginator-wrap > div > button{
    height: 35px;
    border-radius: 0;
}

div.input-wrap div.paginator-wrap{
    padding-top: 0px;
}

div.menu-item-title-static{
    height: 22px;
    line-height: 22px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    padding-left: 12px;
    background-color: rgb(51,51,51);
}

.char-icon{
    text-transform: uppercase;
    font-size: 14px !important;
    font-weight: bold;
}

div.vertical-tool-bar-icon-wrap .char-icon{
    height: 50px;
    line-height: 50px;
    min-height: 50px;
    display: inline-block;
    font-size: 16px !important;
}

div.system-title{
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    
}

div.vertical-tool-bar-icon-wrap span.menu-item-small-child-number{
    display: block;
    position: absolute;
    border-radius: 15pt;
    background-color: #20A8D8;
    color: white;
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 10px;
    left: 25px;
    font-weight: bold;
    text-align: center;
}

.main-view-tool-bar{
    display: table-cell;
    background-color: rgba(51,51,51,1);
    vertical-align: top;
}

.system-version{
    color: #555555;
    font-size: 10px;
    text-align: center;
    position: absolute;
    font-weight: bold;
    top:38px;
    left: 10px;
}

/**
*popup
*/
.p-dialog.p-widget .p-dialog-titlebar{
    padding: 0;
    border-bottom: solid 1px #CCCCCC;
    padding-left: 20px;
    font-size: .875rem;
    color: #151b1e;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
}

.p-dialog.p-widget .p-dialog-titlebar .p-dialog-title{
    font-size: .875rem;
}

.p-dialog .p-dialog-titlebar-close{
    margin-top: 10px;
    margin-right: 10px;
}

.p-corner-all {
    border-radius: 1px;
}

.p-datatable .p-datatable-thead>tr>th, .p-datatable .p-datatable-tfoot>tr>td, .p-datatable .p-datatable-data>tr>td{
    font-size: .875rem;
}

.popup-search-result .p-widget-content .p-datatable-emptymessage{
    height: 390px;
    line-height: 390px;
    text-align: center;
}

.popup-search-result .p-datatable .p-datatable-scrollable-body{
    border-bottom: solid 1px #d9d9d9CC;
    height: 400px;

}

.div-search-filter-painel-actions.small-btns-true{
    min-width: 100px;
    text-align: center;
    padding-top: 30px;
}

.div-search-filter-painel-actions.small-btns-true button{
    height: 35px;
    width: 35px;
}

.center{
    text-align: center;
}

.middle{
    vertical-align: middle;
}

.popup-search-result .input-wrap.paginator-wrap{
    display: block;
    text-align: center;
}

.popup-search-result .input-wrap.paginator-wrap .paginator-wrap{
    text-align: center;
}

div.input-auto-complete-div-result-item > span{
    display: inline-block;
    min-width: 60px;
    border-left: dotted 1px #CCCCCC;
}

.login-form{
    max-width: 500px;
    margin: 0 auto;
    margin-top: 10%;
}

.login-button{
    width: 200px;
    height: 40px;
    margin-top: 10px;
}

.user-details:hover{
    cursor: pointer;
    opacity: 0.5;
}

.inputable-element-wrap .p-selectbutton.p-buttonset.p-widget.p-corner-all{
    height: 36px;
    display: block;
}

.inputable-element-wrap .p-togglebutton.p-button.p-state-default, .p-selectbutton .p-button.p-state-default{
    height: 36px;
    line-height: 36px;
    padding: 0;
    margin: 0;
    width: 95px;
    color: #555555;
    border: solid 1px #CCCCCC;
    background-color: #EFEFEF;
    font-weight: bold;
}

.inputable-element-wrap .p-togglebutton.p-button.p-state-default, .p-selectbutton .p-button.p-state-default:hover{
    cursor: pointer;
    opacity: .5;
}

.inputable-element-wrap .p-button.p-widget.p-state-default.p-button-text-only.p-state-active{
    color: #242424;
    background-color: #CCCCCC;
    font-weight: bold;
    text-decoration: underline;
}

.div-buttons-footer{
    text-align: right;
    padding-top: 20px;
}

.div-buttons-footer .p-button{
    height: 35px;
    border-radius: 2px;
    min-width: 100px;
}

.popup-search-result .p-datatable-row:nth-child(odd):hover{
    background-color: #CCCCCC;
    color: white;
    cursor: pointer;
    opacity: .5;
}

.popup-search-result .p-datatable-row:nth-child(even):hover{
    background-color: #CCCCCC;
    color: white;
    cursor: pointer;
    opacity: 1;
}

.popup-search-result .p-datatable-row:nth-child(even).selected, .popup-search-result .p-datatable-row:nth-child(odd).selected {
    background-color: #CCCCCC;
    font-weight: bold;
    color: white;
    cursor: pointer;
    opacity: 1;
}

button.cs-button{
    font-size: 16px;
    border-radius: 2pt;
    min-width: 150px;
    min-height: 40px;
}

button.cs-button:hover{
    opacity: 0.8;
    cursor: pointer;
}

button.margin-top-as-field{
    margin-top: 20px;
}

div.inputable-element-wrap > div.p-slider.p-widget.p-widget-content.p-corner-all.p-slider-horizontal{
    display: block;
    height: 12px;
    margin-top: 10px;
}

i.material-icons.table-flag-item{
    font-size: 14px;
    color: #555555;
}

.cursor-pointer{
    cursor: pointer;
}

.clickable:hover{
    cursor: pointer;
    opacity: .7;
}

.hover-light:hover{
    opacity: .7;
}

div.div-box-wrap.box-recommended-question{
    min-height: 40px;
}

div.div-box-header i.icon-toggle{
    float: right;
    margin-top: 10px;
    margin-right: 5px;
    cursor: pointer;
}

div.select-button-country-rc .p-button.p-widget.p-state-default.p-button-text-only{
    width: 33%;
}

img.user-picture{
    height: 30px;
    width: 30px;
    position: relative;
    top:10px;
    right:5px;
    border-radius: 15pt;
}

div.input-auto-complete-div-result-item{
    padding: 10px;
    display: table;
    width: 100%;
    text-align: left;
}

div.input-auto-complete-div-result-item > span{
    border: 0px;
    display: table-cell;
}

div.input-auto-complete-div-result-item > span:nth-child(1){
    width: 20%;
    padding-right: 10px;
}

div.input-auto-complete-div-result-item:hover{
    opacity: .5;
}

div.input-auto-complete div.input-auto-complete-div-result {
    overflow:hidden;
    max-width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 5;
}

.right{
    text-align: right;
}

.left{
    text-align: left;
}

.panel-title{
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: solid 1px #CCCCCC;
    margin-bottom: 10px;
    color: rgba(36,36,36,.8);
}

.panel-title > i.fa{
    font-size: 14px;
}

*[class^=p-col] > .input-wrap{
    display: block;
}

.p-progressbar .p-progressbar-value, .p-progressbar-indeterminate .p-progressbar-indeterminate-container{
    border: none;
}

div.ad-performance-total-item{
    border-top: solid 4px #CCCCCC;
    
}

div.ad-performance-total-item div.p-progressbar-value{
    background: #CCCCCC;
}

div.ad-performance-total-item.high{
    border-top: solid 4px #8ec5fc;
}

div.ad-performance-total-item.high div.p-progressbar-value{
    background: #8ec5fc;
}

div.ad-performance-total-item.low div.p-progressbar-value{
    background: red;
}

div.ad-performance-total-item.low{
    border-top: solid 4px red;
}

.ad-performance-total-item div{
    color: rgba(36,36,36,.8);
    font-weight: bold !important;
    font-size: 12px;
}

div.ad-performance-total-item div.div-box-header {
    font-size: .875rem;
    color: #151b1e;
    font-weight: bold;
}

div.ad-performance-total-item i{
    font-size: 24px;
}

div.ad-performance-total-item .lbl{
    text-align: center;
}

div.ad-performance-total-item .text{
    text-align: left;
    font-size: 20px;
    font-family: monospace;
}

div.ad-performance-chart{
    max-height: 400px;
    min-height: 300px;
}

.p-accordion-header.p-state-default.p-corner-all.p-state-active{
    border-bottom: solid 1px #CCCCCC;
    font-weight: bold;
}

body .p-accordion .p-accordion-header a{
    border: solid 1px transparent;
    border-radius: 0pt;
    background-color: rgba(0,0,0,.1);
    color: rgba(0,0,0,.9);
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a{
    background-color: #CCCCCC;
    border-radius: 0pt;
    color: rgba(0,0,0,.9);
    border: solid 1px transparent;
}

.p-accordion .p-accordion-header a>span.pi{
    display: none;
}

.div-info-box-wrap{
    border: solid 1px #CCCCCC;
    border-radius: 1pt 1pt 1pt 1pt;
}

.div-info-box-wrap div.icon{
    background-color: #CCCCCC;
    vertical-align: middle;
    color:white;
    width: 20px;
    text-align: center;
}

.div-info-box-wrap div.text{
    padding: 4px;
}

.div-info-box-wrap i{
    font-size: 20px;
}

.p-accordion-content .half-size:nth-child(1) .ad-performance-total-item{
    margin-left: 0;
}

.p-accordion-content .half-size:nth-child(2) .ad-performance-total-item{
    margin-right: 0;
}

div.main-view-root-div-top-bar > div.div-p-update-info-wrap{
    position: relative;
    top:-2px;
}

div.info-box-charts-ad-performance{
    margin-top: 18px;
}

div.info-box-charts-ad-performance-message{
    margin:20px;
    width: 95%;
}

div.prommoted-ad-badge {
    background-color: antiquewhite;
    border: solid 1px #CCCCCC;
    border-radius: 8pt 0pt 8pt 0pt;
    padding: 4px;
    margin-top: 5px;
    text-align: center;
    display: inline-flex;
    align-self: flex-end;
    opacity: .8;
}

div.prommoted-ad-badge i{
    font-size: 30px;
}

span.legend-color-box{
    display: inline-block;
    height: 12px;
    width: 12px;
    border: solid 1px #CCCCCC;
    padding-right: 10px;
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.ad-performance-global-legend{
    border: solid 3px #E0DFE0;
    border-radius: 2pt;
    display: inline-block;
    text-align: left;
    padding: 4px;
    margin-top: 40px;
}

div.ad-performance-global-legend > div{
    line-height: 30px;
    vertical-align: top;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
    color: rgba(36,36,36,.8);
}

div.sum-percent{
    width: 30%;
    vertical-align: top;
}

div.sum-bar{
    width: 70%;
    vertical-align: top;
}

div.div-ad-performance-total-item{
    position: relative;
    top:15px;
}

div.summary-section-title{
    text-align: center;
    font-weight: bold;
    color: rgba(36,36,36,.8);
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.info-box-gauge-percent{
    display: inline-block;
    width: 280px;
    position: relative;
    border:none;
    top: -30px;
    border-top-right-radius: 8pt;
    border-bottom-right-radius: 8pt;
    font-size: 12px;
}

.info-box-gauge-percent div.icon{
    background-color: transparent;
    color: #555555;
}

.info-box-gauge-percent div.icon i{
    font-size: 16px;
}

/*making the select button full-sized*/
div.nerd-p-div-root .inputable-element-wrap .p-selectbutton.p-buttonset.p-widget.p-corner-all{
    display: table;
    width: 100%;
}

div.nerd-p-div-root .p-selectbutton.p-buttonset.p-widget.p-corner-all > .p-button.p-widget.p-state-default.p-button-text-only{
    display: table-cell;
}

div.nerd-p-div-root .inputable-element-wrap .p-button.p-widget.p-state-default.p-button-text-only.p-state-active{
    border-color: rgba(0,0,0,.1);
}

div.nerd-p-div-root .inputable-element-wrap .p-togglebutton.p-button.p-state-default, .p-selectbutton .p-button.p-state-default{
    height: 32px;
    line-height: 32px;
}

div.nerd-p-div-root .div-loader-bar{
    height: 60px;   
}


/*fix on the prime react responsive grid*/
.p-grid.row-div-wrap .p-col-1, .p-grid.row-div-wrap .p-col-2,.p-grid.row-div-wrap .p-col-3,.p-grid.row-div-wrap .p-col-4,.p-grid.row-div-wrap .p-col-5,.p-grid.row-div-wrap .p-col-6,.p-grid.row-div-wrap .p-col-7,.p-grid.row-div-wrap .p-col-8,.p-grid.row-div-wrap .p-col-9,.p-grid.row-div-wrap .p-col-10,.p-grid.row-div-wrap .p-col-11,.p-grid.row-div-wrap .p-col-12{
    padding: 0;
}
.p-grid.row-div-wrap{
    margin: 0;
}
/*fix on the prime react responsive grid ends here*/

.faded{
    opacity: .3;
}

.nerd-p-div-root .p-button{
    height: 36px;
    padding-right: 15px;
    padding-left: 15px;
}

.nerd-div-info-content{
    font-size: 12px;
    border-top: solid 1px rgba(0,0,0,.1);
    padding-top: 5px;
}

.nerd-div-info-item{
    border:solid 1px rgba(0,0,0,.1);
    margin-bottom: 4px !important;
    padding: 4px;
}

.nerd-div-info-item i{
    font-size: 20px;
    color: rgba(0,0,0,.5);
}

.role-result-item-batch-header{
    font-weight: bold;
    background-color: rgba(0,0,0,.1);
    padding: 4px;
    height: 35px;
    line-height: 35px;
}

.role-result-item-batch{
    padding: 4px;
    height: 35px;
    line-height: 35px;
    border-bottom: solid 1px rgba(0,0,0,.1);
}

.role-result-normalized-value-item{
    margin-right: 5px;
}

.csv-button-div-wrap{
    margin-top: 10px;
}

.fa-icon-tip{
    font-size: 25px;
    padding-left: 2px;
    vertical-align: middle;
    color: rgba(0,0,0,.5);
}

.div-info-inline{
    background-color: rgba(255,255,255,.5);
    font-size: 12px;
}

.aslink{
    text-decoration: underline;
    opacity: .8;
}

.location-result-as-table{
    display: table;
    width: 100%;
}

.location-item-result{
    
    padding: 10px;
    margin: 5px;
    border: solid 1px rgba(0,0,0,.2);
    border-radius: 2pt;
    background-color: white;

    display: table-row;
    width: 100%;
}

.location-item-result-data{
    padding-left: 10px;
    font-size: 15px;
    display: table-cell;
    border: solid 1px rgba(0,0,0,.2);
    height: 50px;
    line-height: 50px;
}

.location-item-result-data a{
    color: black;
    text-align: center;
}

.location-item-result-data.selected{
    font-weight: bold;
}

.location-result-header-wrap{
    display: table-row;
    width: 100%;
}

.location-result-header-wrap > div{
    display: table-cell;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;

}

.no-records-found-div{
    font-size: 16px;
    background-color: white;
    text-align: center;
    border: solid 1px rgba(0,0,0,.2);
    padding: 10px;
}

.location-item-result-button{
    width: 50px;
    text-align: center;
    border: solid 1px rgba(0,0,0,.2);
    height: 50px;
    line-height: 50px;
    display: table-cell;
}

.location-item-result-button.header{
    height: 30px;
    border: none 0;
    line-height: 30px;
    padding-bottom: 5px;

}

.location-item-result-button i{
    font-size: 20px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 2pt;
    background-color: #5cb85c;
    color:white;
}

.location-item-result-group{
    
    width: 100%;
}

.location-item-result-hidden{    
    width: 100%;
    height: 50px;
}

.location-item-result-hidden > td{    
    font-size: 16px;
    background-color: white;
    vertical-align: top;
    border: solid 1px rgba(0,0,0,.2);
    padding: 10px;
    padding-top: 12px;
    position: relative;
    top:-3px;
}

.location-item-result-hidden > td > span{
    padding-right: 20px;
}    

.location-item-result-hidden > td > span > label{
    font-weight: bold;
}


.location-item-result-hidden > td > span
{
    min-width: 200px;
    display: inline-block;
}

.location-item-result-hidden > td > span:nth-child(1)
{
    min-width: 400px;
}

.location-item-result-row-separator{
    height: 20px;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 500ms;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

#div-current-view-title{
    text-align: left;
    padding-left: 50px;
}

div.main-view-root-div-content > div:not(.main-view-root-div-top-bar){
    height: 95%;
}

#root{
    overflow: hidden;
}