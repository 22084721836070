div.message-email-not-verified {
   display: flex;
   flex-flow: row;
   justify-content: space-around;
   margin-top: 100px;
}

div.message-email-not-verified > div{
    box-shadow: 5px 5px 5px rgba(0,0,0,.3);
    width: 400px;
}