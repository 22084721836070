.bullet { font: 10px sans-serif; }
.bullet .marker { stroke: #000; stroke-width: 2px; }
.bullet .tick line { stroke: #666; stroke-width: .5px; }
.bullet .range.s0 { fill: #eee; }
.bullet .range.s1 { fill: #ddd; }
.bullet .range.s2 { fill: #ccc; }
.bullet .measure.s0 { fill: lightsteelblue; }
.bullet .measure.s1 { fill: steelblue; }
.bullet .title { font-size: 14px; font-weight: bold; }
.bullet .subtitle { fill: #999; }

div.bullet-chart-wrap{
    padding-top: 10px;
    padding-bottom: 15px;
}

div.bullet-chart-div-labels-container{
    width: 100%;
    text-align: center;
}

div.bullet-chart-div-labels{
    height: 10px;
    display: inline-table;
}

div.bullet-chart-div-label-item{
    display: table-cell;
    font-size: 10px;
    font-weight: bold;
    padding: 10px;
    vertical-align: middle;
    line-height: 10px;
    color: rgba(36,36,36,.8);
    padding-top:16px;
}

span.label-square-color{
    height: 10px;
    line-height: 10px;
    border: solid 1px #CCCCCC;
    width: 10px;
    margin-right: 2px;
    position: relative;
    top:1px;
    display: inline-block;
}

div.bullet-chart-title{
    text-align: center;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: rgba(36,36,36,.8);
}