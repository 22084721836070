div.div-search-filter-painel div.div-search-filter-painel-title{
    font-size: 20px;
    padding: 5px;
}


div.div-search-filter-painel-components{
    display: table;
  }
  
  div.div-search-filter-painel-filters, div.div-search-filter-painel-actions{
    display: table-cell;
    vertical-align: top;
    padding: 5px;
  }
  
  div.div-search-filter-painel-filters{
    width: 100%;
  }
  
  div.div-search-filter-painel-actions{
    min-width: 200px;
    text-align: center;
  }

  div.div-search-filter-painel-actions button{
      padding: 5px;
      font-size: 16px;
      background-color: white;
      border: solid 1px black;
      border-radius: 2pt;
  }

  div.div-search-filter-painel-actions button:hover{
      opacity: 0.8;
      cursor: pointer;
  }

  div.div-search-filter-painel-actions.small-btns-false button > span:nth-child(1){
    display: none;
  }