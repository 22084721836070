div.tab-container{
    color: #242424;
}

div.tab-container div.tab-item{
    display: none;
    background-color: #f0f3f5;
    min-height: 506px;
    border:solid 1px #CCCCCC;

}

div.tab-container div.tab-tabs-reagion{
    display: table;
    height: 30px;
    width: 300px;
    text-align: center;


}

div.tab-container div.tab-tabs-reagion div.tab-head{
    display: table-cell;
    width: 200px;
    color: #151b1e;
    height: 40px;
    line-height: 40px;
    border: solid 1px transparent;
}

div.tab-container div.tab-tabs-reagion div.tab-head:hover{
    cursor: pointer;
    text-decoration: underline;
}

div.tab-container div.tab-tabs-reagion div.tab-head.selected{
    border-color: #CCCCCC;
    border-bottom: #f0f3f5;
    position: relative;
    bottom: -2px;
}

div.tab-container div.tab-tabs-reagion div.tab-head{
    background-color: transparent;
}
div.tab-container div.tab-item{
    background-color: white;
}

div.tab-container div.tab-tabs-reagion div.tab-head.selected{
    border-bottom: white;
    background-color: white;
}