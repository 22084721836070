.md-output-wrap .div-box-body{
    background-color: white;
}

.md-output-wrap p{
    white-space: normal;
}

.md-output-wrap pre{
    white-space: pre-wrap !important;
}