div.input-auto-complete{
    display: inline-block;
}
div.input-auto-complete div.input-auto-complete-div-result{
    position: absolute;
    border: 1px solid black;
    display: none;
    text-align: left;
    background-color: white;
    min-width: 400px;
}

div.input-auto-complete  div.input-auto-complete-div-result-item.selected{
    text-decoration: underline;
}

div.input-auto-complete  div.input-auto-complete-div-result-item{
    cursor: pointer;
}