div.vertical-tool-bar-wrap{
    background-color: rgba(51,51,51,1);
    width: 250px;
    vertical-align: top;
}

div.vertical-tool-bar-wrap div.vertical-tool-bar-icons{
    min-width: 50px;
    
    display: inline-block;
}

div.vertical-tool-bar-wrap div.vertical-tool-bar-body{
    width: 300px;
    display: inline-block;
    min-height: 700px;
    background-color: rgba(37, 37, 38, 1);
    vertical-align: top;    
    
    background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

div.vertical-tool-bar-wrap div.vertical-tool-bar-icons div.vertical-tool-bar-icon-wrap i{
    color:rgba(173,173,173,1);
    font-size: 30px;
    line-height: 50px;
    width: 46px;
    text-align: center;
    vertical-align: middle;
    
    cursor: pointer;
}

div.vertical-tool-bar-wrap div.vertical-tool-bar-icons div.vertical-tool-bar-icon-wrap i.selected{
    color:rgba(255,255,255,1);
    
}

div.vertical-tool-bar-body{
    display: none;
    color: white;
}

div.vertical-tool-bar-wrap i.icon-toggle{
    font-size: 38px;
    cursor: pointer;
}

div.vertical-tool-bar-wrap img{
    width:100%; 
}

div.vertical-tool-bar-wrap{
    width: 50px;
}


/*menuitem css expanded*/
div.vertical-tool-bar-wrap div.menu-item-root{
    margin-left: 0px;
    padding: 0px;
    font-weight: normal;
    border-top: 0 none;
}


div.vertical-tool-bar-wrap .menu-items-area > div.menu-item-root{
    border: 0 none;
    border-radius: 4pt;
    margin-bottom: 5px;
    font-weight: bold;
}


div.vertical-tool-bar-wrap div.menu-item-root i{
    font-size: 20px;
    min-width: 24px;
    text-align: center;
    padding-left: 10px;
    padding-right: 2px;
}

div.vertical-tool-bar-wrap div.menu-item-root i.ident{
    padding-left: 20px;
}

div.vertical-tool-bar-wrap div.menu-item-root i.material-icons{
    position: relative;
    top:4px;
}

div.vertical-tool-bar-wrap div.menu-item-root i:hover{
    cursor: pointer;
    text-decoration: underline;
    opacity: 1;
}

div.vertical-tool-bar-wrap div.menu-item-root > div.head{
    display: table;
    width: 100%;
    text-align: left;
}

div.vertical-tool-bar-wrap div.menu-item-root > div.head span{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}


div.vertical-tool-bar-wrap div.menu-item-root > div.body{
    display: block;
    
}

div.vertical-tool-bar-wrap div.menu-item-root > div.body{
    display: none;
}

div.vertical-tool-bar-wrap div.menu-item-root > div.head > *{
    display: table-cell;
}

div.vertical-tool-bar-wrap div.menu-item-root.selected > div.head{
    background-color: #555555;
    font-weight: bold;
    
}

div.vertical-tool-bar-wrap div.menu-item-root.selected > div.head > i{
    color: #20A8D8;
    
}

div.vertical-tool-bar-wrap div.menu-item-root.selected > div.head > span{
    color: white;
    
}
div.vertical-tool-bar-wrap div.menu-item-root > div.head:hover i{
    color: rgba(255,255,255,1);
}
div.vertical-tool-bar-wrap div.menu-item-root > div.head:hover{
    background-color: #20A8D8;
    opacity: 1;
    color: rgba(255,255,255,1);
}
div.vertical-tool-bar-wrap div.menu-item-root > div.head > span:hover{
    cursor: pointer;
    text-decoration: underline;
    opacity: 1;
}

div.vertical-tool-bar-wrap i.not-expanded-icon{
    display: none;
}

div.vertical-tool-bar-item-wrap{
    display: none;
}

div.vertical-tool-bar-wrap div.vertical-tool-bar-body{
    display: none;
}

div.vertical-tool-bar-wrap div.vertical-tool-bar-body.showed{
    display: inline-block;
}

div.vertical-tool-bar-wrap div.vertical-tool-bar-icons div.vertical-tool-bar-icon-wrap i{
    border-left: 4px solid transparent;
}

div.vertical-tool-bar-wrap div.vertical-tool-bar-icons div.vertical-tool-bar-icon-wrap i:hover{
    border-left: 4px solid #20A8D8;
    cursor: pointer;
}

