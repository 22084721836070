div.div-ui-update-info-wrap{
    width: 30px;
}

.play-shake{
  animation: shake 2s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 500px;
  animation-iteration-count: infinite;
  
}
.play-change-color{
    animation-name: change-color;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

@keyframes change-color {
    0%   {color: black;}
    25% {color: yellowgreen;}
    50%   {color: yellow;}
    100% {color: green;}
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(0,-1px, 0);
    }
    
    20%, 80% {
      transform: translate3d(0,2px, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(0, -3px, 0);
    }
  
    40%, 60% {
      transform: translate3d(0, 3px, 0);
    }
  }

div.div-ui-update-info-wrap .ui-overlaypanel{
    line-height: unset;
    width: 200px;
}

.ui-overlaypanel .div-info-box-wrap{
   margin-top: 10px;
   font-size: 12px;
}
